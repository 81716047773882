import { Component, OnInit } from '@angular/core';
import {
  IconDefinition,
  faTwitterSquare,
  faYoutubeSquare,
  faLinkedin,
  faFacebookSquare
} from '@fortawesome/free-brands-svg-icons';

import SharedService from '../../shared/shared.service';

import { DataEntity, INav, INavObject } from '../../shared/model/model.index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isExpanded: boolean;
  isSearching: boolean;
  isSearchTextFocus: boolean;
  searchText: string;
  navMenuItems: INav[] = [];

  faTwitter: IconDefinition = faTwitterSquare;
  faYoutube: IconDefinition = faYoutubeSquare;
  faLinkedin: IconDefinition = faLinkedin;
  faBilibili: IconDefinition = faLinkedin;
  faFacebook: IconDefinition = faFacebookSquare;

  constructor(private sharedService: SharedService,
    private router: Router) {

  }

  ngOnInit() {
    this.getNavMenuItems();
  }

  getNavMenuItems(): void {
    this.sharedService.getNavMenu().subscribe((response: INavObject) => {
      //this.navMenuItems = items;
      const items: DataEntity[] = response.data;


      //const sub_navs:   items.attributes.sub_navs.map(s => {
      //   const subNavs: INav = {
      //     ...s,
      //     id: s.id,

      //     url: s.url,
      //     name: s.name,
      //     sub_navs:null,
      //     published_at: s.published_at, created_at: s.created_at, updated_at: x.attributes.updatedAt
      //   };
      //   return subNavs;
      // })



      const menuItems = items.map(x => {
        const allNavs: INav = {
          id: x.id, ...x.attributes,
          url: x.attributes.url,
          published_at: '', created_at: '', updated_at: '',
          sub_navs: x.attributes.sub_navs
        };


        return allNavs;
      });

      this.navMenuItems = menuItems;
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  onSearch() {
    this.isSearching = !this.isSearching;
    this.router.navigate(['/search', this.searchText.trim()]);
    this.isSearching = !this.isSearching;
  }

  searchTextFocus() {
    this.isSearchTextFocus = !this.isSearchTextFocus;
  }
}
