<footer class="footer p-4">
  <div class="container">
    <div class="content is-size-7">
     
        <div class="column has-text-centered p-0">
         
          <div class="is-size-5">  Center for Stem Cell Biology </div>
          <br />

          <a href="https://www.mskcc.org/research/ski/labs/danwei-huangfu" target="_blank"> Huangfu lab</a> |
          <a href="https://www.mskcc.org/research/ski/labs/lorenz-studer" target="_blank"> Studer lab</a> |
          <a href="https://www.mskcc.org/research/ski/labs/thomas-vierbuchen" target="_blank">Vierbuchen lab</a> |
          <a href="https://www.mskcc.org/research/ski/core-facilities/stem-cell-research-facility" target="_blank">Ting Zhou lab</a> |
          <a href="https://www.mskcc.org/research/ski/programs/developmental-biology" target="_blank">DevBio</a> |
          <a href="https://www.mskcc.org/" target="_blank">MSK</a>
        
      </div>
    </div>
        
    </div>

</footer>
