import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { PRUEBA, PRUEBA_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
  styleUrls: ['./prueba.component.css']
})
export class PruebaComponent extends BaseComponent implements OnInit {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = PRUEBA;
    this.titleService.setTitle(PRUEBA_TITLE);
  }

  //ngOnInit() {
  //  this.getPage();
  //  this.getContent('datetime:DESC');
  //}
}
