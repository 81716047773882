import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { INav, IPage, ISlider, ISliderData, ISliderObject, IImage } from '../../shared/model/model.index';

import { STORY,  HOME, MISSION, NEWS_EVENTS } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';

import {
  IconDefinition,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  sliders: ISlider[];
  _sharedService: SharedService;
  
  contentsEvents: any;
  contentsHome: any;
  baseUrl: string = environment.cmsApiBaseUrl;
  public faPlay: IconDefinition = faPlayCircle;
  story: IPage;
  mission: IPage;
  newsevents: IPage;

  constructor(sharedService: SharedService,
    //public sanitizer: DomSanitizer,
    private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = HOME;
    this._sharedService = sharedService;
    this.titleService.setTitle("MorPhiC");
    this.getSliders();
    this.getData();

  }

  ngOnInit() {
    this.getContent('datetime:DESC');
  }

  getData(): void {

    this._sharedService.getPageByUID(MISSION).subscribe(p => {
      if (p) {

        const attr = p.data[0].attributes;
        const navP: INav = { created_at: "", id: 1, is_disabled: false, is_external: true, is_parent: false, name: "mission", published_at: "", sub_navs: null, updated_at: "", url: "mission" }

        const thisPage: IPage = {
          id: p.data[0].id, content_display_type: null,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url: attr.url
        }

        this.mission = thisPage;
      }
    });


    this._sharedService.getPageByUID(STORY).subscribe(p => {
      if (p) {

        const attr = p.data[0].attributes;
        const navP: INav = { created_at: "", id: 1, is_disabled: false, is_external: true, is_parent: false, name: "mission", published_at: "", sub_navs: null, updated_at: "", url: "mission" }

        const thisPage: IPage = {
          id: p.data[0].id, content_display_type: null,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url: attr.url
        }

        this.story = thisPage;
      }
    });


    this._sharedService.getPageByUID(NEWS_EVENTS).subscribe(p => {
      if (p) {

        const attr = p.data[0].attributes;
        const navP: INav = { created_at: "", id: 1, is_disabled: false, is_external: true, is_parent: false, name: "news-events", published_at: "", sub_navs: null, updated_at: "", url: "mission" }

        const thisPage: IPage = {
          id: p.data[0].id, content_display_type: null,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url: attr.url
        }

        this.newsevents = thisPage;
      }
    });

    this.getHomeContent();

  }

  getHomeContent(sort: string = null) {
    this._sharedService.getPageContentByID(HOME, sort).subscribe(c => {
      this.contentsHome = c;
      if (this.contentsHome.length > 0) {
        this.selectedTitle = this.contentsEvents[0].title;
        if (this.firstLevel) {
          this.selectedTitle = this.firstLevel;
        }
        if (this.contentsHome[0].subcontents.length > 0) {
          this.selectedSubTitle = this.contentsHome[0].subcontents[0].title;
          if (this.secondLevel) {
            this.selectedSubTitle = this.secondLevel;
          }
        }
      }
    });
  }

  getSliders(): void {
    this._sharedService.getSliders().subscribe((resp: ISliderObject) => {
      const items: ISliderData[] = resp.data;



      const allSliders = items.map(x => {

        const iimgg: IImage = {
          alternativeText: 'x.attributes.image.data.attributes.alternativeText',
          caption: x.attributes.image.data.attributes.caption,
          created_at: '', updated_at: '',
          ext: '',
          formats: x.attributes.image.data.attributes.formats,
          hash: '',
          height: x.attributes.image.data.attributes.height,
          id: x.id,
          mime: '',
          name: x.attributes.image.data.attributes.name,
          previewUrl: '',
          provider: '',
          provider_metadata: '',
          size: x.attributes.image.data.attributes.formats.large.size,
          url: x.attributes.image.data.attributes.formats.large.url.replace('/uploads', '/../uploads').replace('large_', ''),
          width: x.attributes.image.data.attributes.width

        };



        const s: ISlider = {
          id: x.id,
          is_active: x.attributes.is_active,
          is_external: x.attributes.is_external,
          order: x.attributes.order,
          url: x.attributes.url,
          updatedAt: '',
          createdAt: '',
          publishedAt: '',
          image: iimgg

          //image: x.attributes.image.data.map(i=> {

          //  const iimg: IImage ={
          //    alternativeText:  i.attributes.alternativeText,
          //    caption: i.attributes.caption,
          //    url: i.attributes.formats.large.url,
          //    ext: i.attributes.formats.large.ext,
          //    formats: i.attributes.formats,
          //    hash: i.attributes.formats.large.hash,
          //    height: i.attributes.formats.large.height,
          //    id: i.id,
          //    mime: i.attributes.formats.large.mime,
          //    name: i.attributes.formats.large.name,
          //    previewUrl: '',
          //    provider: '',
          //    provider_metadata: null,
          //    size: 50,
          //    updated_at: null,
          //    width: 50,
          //    created_at: ''

          //  };
          //  return iimg;
          // })

        };
        return s;
      });

      this.sliders = allSliders.filter(i => i.image !== null);
    });

  }
}
