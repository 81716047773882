<section class="content">
  <section class="section" *ngIf="page">
    <div class="container">
      <h3 class="title">
        {{page.title}}
      </h3>
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | oembed | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm">
        <figure class="image">
          <a target="_blank" href="https://www.mskcc.org/videos/inside-msk-flow-cytometry-core-facility">
            <img style="width: 50%" src="../../assets/images/FCCFTeam.png">
            <fa-icon class="faPlay" [icon]="faPlay"></fa-icon>
          </a>
        </figure>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="contents.length > 0">
        <div class="box" *ngFor="let content of contents" [id]="content.content_uid">
          <article class="media">
            <div class="media-left">
              <figure class="image is-128x128">
                <!-- img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].formats.thumbnail.url" [alt]="content.title" -->
                <img *ngIf="content.images.length" class="is-rounded" [src]="content.images[0].formats.thumbnail.url" [alt]="content.title">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                <span class="ml-1">{{content.sub_title}}</span>
                <br>
                <div [innerHTML]="content.description | safeHtml"></div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</section>
