import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {

  NewsEventsComponent,
  TeamComponent,
  AboutUsComponent,
  ContactUsComponent,
  LegalDisclaimerComponent,
  SitemapComponent,
  SearchComponent 
} from './component.index';

import { ComponentRoutes } from './component.routes';
import { LocationComponent } from './location/location.component';
import { NewsComponent } from './news/news.component';
import { StoryComponent } from './story/story.component';
import { PruebaComponent } from './prueba/prueba.component';

import { AvailableComputingSystemComponent } from './available-computing-system/available-computing-system.component';
import { PublicationsComponent } from './publications/publications.component';
import { ProtocolsComponent } from './protocols/protocols.component';
import { CellLinesComponent } from './cell-lines/cell-lines.component';
import { ResearchComponent } from './research/research.component';

@NgModule({
  declarations: [

    NewsEventsComponent,
    TeamComponent,
    AboutUsComponent,
    ContactUsComponent,
    LegalDisclaimerComponent,
    SitemapComponent,
    SearchComponent,
    LocationComponent,
    NewsComponent,
    StoryComponent,
    PruebaComponent,
    AvailableComputingSystemComponent,
    PublicationsComponent,
    ProtocolsComponent,
    CellLinesComponent,
    ResearchComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    RouterModule.forChild(ComponentRoutes),
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule
  ]
})
export class ComponentModule { }
