export * from './cell-lines/cell-lines.component';
export * from './protocols/protocols.component';
export * from './publications/publications.component';
export * from './news-events/news-events.component';
export * from './team/team.component';
export * from './about-us/about-us.component';
export * from './contact-us/contact-us.component';
export * from './legal-disclaimer/legal-disclaimer.component';
export * from './sitemap/sitemap.component';
export * from './search/search.component';
export * from './location/location.component';
export * from './story/story.component';
export * from './news/news.component';
export * from './research/research.component';


export * from './prueba/prueba.component';

export * from './temp/temp.component';
