import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { AVAILABLE_COMPUTING_SYSTEM, AVAILABLE_COMPUTING_SYSTEM_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-available-computing-system',
  templateUrl: './available-computing-system.component.html',
  styleUrls: ['./available-computing-system.component.css']
})
export class AvailableComputingSystemComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = AVAILABLE_COMPUTING_SYSTEM;
    this.titleService.setTitle(AVAILABLE_COMPUTING_SYSTEM_TITLE);
  }

 

}
