<section class="content section">
  <div class="container">
    <h5 style="color:lightblue"><i>Search Results of {{searchText}}</i> </h5>
    

    <div class="m-b-sm m-t-sm">
      <div *ngIf="contents.length > 0">
        
        <div *ngFor="let content of contents" class="mb-5" [id]="content.content_uid">
          <a href={{content.url}}  title={{content.page.title}}> <div style="background-color:#f5f5f5!important; padding:10px 0; color:black; font-size:x-large; font-weight:600">  {{content.title}}  <span style="font-size:medium">({{content.page.title}})</span> </div> </a>

          <div [innerHTML]="content.description | safeHtml"></div>


        </div>
      </div>
    </div>


    <hr *ngIf="contents.length > 0 && pages.length > 0" style="    border: 3px solid hsl(201,95%,56%)" />
    <div class="m-b-sm m-t-sm">
    <div *ngIf="pages.length > 0">
      <div *ngFor="let page of pages" class="mb-5" [id]="page.uid">
        <a href={{page.url}} title={{page.title}}> <div style="background-color:#f5f5f5!important; padding:10px 0; color:black; font-size:x-large; font-weight:600">  {{page.title}}   </div> </a>
        <div [innerHTML]="page.description | safeHtml"></div>
        <div [innerHTML]="page.notification | safeHtml"></div>
      </div>
    </div>
  </div>


  </div>
</section>
