<header>
  <div class="is-full-widescreen is-header-region">
    <div class="container">
      <nav class="top-nav navbar is-transparent" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="/" title="MorPhiC">
            <img src="../../assets/images/MorPhiC-transparent-logo.png">
          </a>
        </div>
        <div class="navbar-menu">
          <div class="navbar-end">
            <div class="navbar-item">

              <div class="field has-addons search-addon">
                <div class="control">
                  <input class="input search-addon" type="text" id="quick-search-input" [ngClass]="{ active: isSearchTextFocus }" placeholder="Search"
                         (focus)="searchTextFocus()" (blur)="searchTextFocus()" [ngStyle]="{'width':isSearchTextFocus ? '400px' : ''}"
                         (keyup.enter)="onSearch()" [(ngModel)]="searchText" />
                </div>
                <div class="control">
                  <a id="search-button" class="button is-search search-addon" [ngClass]="{ 'is-loading': isSearching, active: isSearchTextFocus }" (click)="onSearch()">
                    <span class="icon">
                      <i class="mdi mdi-magnify mdi-24px"></i>
                    </span>
                  </a>
                </div>
              </div>

            </div>
            <div class="navbar-brand">
              <a class="navbar-item" href="/">
                <img src="../../assets/images/MSKLogo.jpg">
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <nav class="navbar is-link" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a role="button" class="navbar-burger burger" aria-label="menu" [attr.aria-expanded]="isExpanded"
           data-target="navbarBasicExample" [ngClass]="{ 'is-active': isExpanded }" (click)="toggle()">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu" [ngClass]="{ 'is-active': isExpanded }">
        <div class="navbar-start center">
          <ng-container *ngFor="let navMenuItem of navMenuItems">
            <ng-container *ngIf="navMenuItem.sub_navs && navMenuItem.sub_navs.data.length == 0; else nestedNav">
              <a class="navbar-item" [attr.href]="navMenuItem.url" target="_blank" *ngIf="navMenuItem.is_external">
                {{navMenuItem.name}}
              </a>
              <a class="navbar-item" [routerLink]="navMenuItem.url" *ngIf="!navMenuItem.is_external">
                {{navMenuItem.name}}
              </a>
            </ng-container>

            <ng-template #nestedNav>
              <div class="navbar-item is-hoverable has-dropdown">
                <a class="navbar-link">
                  {{navMenuItem.name}}
                </a>
                <div class="navbar-dropdown" *ngIf="navMenuItem.sub_navs.data && navMenuItem.sub_navs.data.length > 0">
                  <ng-container *ngFor="let sub_nav of navMenuItem.sub_navs.data">
                    <a class="navbar-item" [attr.href]="sub_nav.attributes.url" target="_blank" *ngIf="sub_nav.attributes.is_external && !sub_nav.attributes.is_disabled">
                      {{sub_nav.attributes.name}}
                    </a>
                    <a class="navbar-item" [routerLink]="sub_nav.attributes.url" *ngIf="!sub_nav.attributes.is_external && !sub_nav.attributes.is_disabled">
                      {{sub_nav.attributes.name}}
                    </a>
                  </ng-container>
                </div>


              </div>
            </ng-template>
          </ng-container>

        </div>
        <!--<div class="navbar-end">
          <div class="navbar-item">

          </div>
        </div>-->
      </div>
    </div>
  </nav>
</header>
