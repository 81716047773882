<div class="" style="height: 10rem; position: relative;">
  <h1 style="top:60px;position:absolute; left:212px; font-size:xx-large; font-weight: bolder; color:yellow; height:8rem">ABOUT US:{{page.title}}</h1>
  <img src="../../../assets/images/rts-header.jpeg" style="height:100%; width:100%" />
</div>


<section class="content section">
  <div class="container" *ngIf="page">

    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>


    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="editor ql-editor center-img-about " [innerHTML]="page.notification | safeHtml">
      </div>
    </div>



    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="false"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="false"></tabs>
    </div>

    <accordion *ngIf="page.content_display_type.display_type_uid == contentDisplayType.ACCORDION" [contents]="contents"
               [contentTemplate]="contentTemplate" [subcontentTemplate]="subcontentTemplate" [loadContentTemplate]="true"
               [loadSubcontentTemplate]="true" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>
  </div>
</section>



  <ng-template #contentTemplate let-content="content">
    <div class="m-b-sm m-t-sm columns m-2" *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST"  style="margin:auto; width:30%">
    </div>
  </ng-template>


