

<section class="content section">
  <div class="container" *ngIf="page">

    <div class="m-b-sm m-t-sm " *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
    </div>


    <!--<div class="m-b-sm m-t-sm" *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <div class="" *ngFor="let content of contents" [id]="content.content_uid">
        <article class="media">
          <div class="media-left" *ngIf="content.images.length">
            <figure class="image is-128x128">
              <img *ngIf="content.images.length" class="is-rounded" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title">
            </figure>
          </div>
          <div class="media-content" >
            <div class="content ">
              <div style="background-color: navy; color: white!important; width:15em" [innerHTML]="content.description | safeHtml"></div>
            </div>
          </div>
        </article>
      </div>
    </div>-->





  </div>
</section>

