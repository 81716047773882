import { Routes } from '@angular/router';
import { AvailableComputingSystemComponent } from './available-computing-system/available-computing-system.component';
import {
  TeamComponent,
  AboutUsComponent,
  ContactUsComponent,
  LegalDisclaimerComponent,
  SitemapComponent,
  SearchComponent,
  LocationComponent,
  StoryComponent,
  PruebaComponent,
  PublicationsComponent,
  ProtocolsComponent,
  CellLinesComponent,
  ResearchComponent,
  NewsComponent
} from './component.index';
import { HomeComponent } from './home/home.component';

export const ComponentRoutes: Routes = [

  { path: 'home', component: HomeComponent },
  { path: 'available-computing-system', component: AvailableComputingSystemComponent },
  {
    path: 'cell-lines/:firstlevel/:secondlevel/:thirdlevel',
    component: CellLinesComponent
  },
  {
    path: 'cell-lines/:firstlevel/:secondlevel',
    component: CellLinesComponent
  },
  {
    path: 'cell-lines/:firstlevel',
    component: CellLinesComponent
  },
  {
    path: 'cell-lines',
    component: CellLinesComponent
  }, 
  {
    path: 'protocols/:firstlevel/:secondlevel/:thirdlevel',
    component: ProtocolsComponent
  },
  {
    path: 'protocols/:firstlevel/:secondlevel',
    component: ProtocolsComponent
  },
  {
    path: 'protocols/:firstlevel',
    component: ProtocolsComponent
  },
  {
    path: 'protocols',
    component: ProtocolsComponent
  },
  {
    path: 'publications/:firstlevel/:secondlevel/:thirdlevel',
    component: PublicationsComponent
  },
  {
    path: 'publications/:firstlevel/:secondlevel',
    component: PublicationsComponent
  },
  {
    path: 'publications/:firstlevel',
    component: PublicationsComponent
  },
  {
    path: 'publications',
    component: PublicationsComponent
  },

  { path: 'about/:firstlevel/:secondlevel/:thirdlevel',    component: AboutUsComponent  },
  { path: 'about/:firstlevel/:secondlevel',    component: AboutUsComponent  },
  { path: 'about/:firstlevel',    component: AboutUsComponent  },
  { path: 'about', component: AboutUsComponent },

  { path: 'team/:firstlevel/:secondlevel/:thirdlevel', component: AboutUsComponent },
  { path: 'team/:firstlevel/:secondlevel', component: AboutUsComponent },
  { path: 'team/:firstlevel', component: AboutUsComponent },
  { path: 'team', component: AboutUsComponent },

  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'legal-disclaimer',
    component: LegalDisclaimerComponent
  },
  {
    path: 'sitemap',
    component: SitemapComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'search/:searchText',
    component: SearchComponent
  },

  { path: 'location/:firstlevel/:secondlevel/:thirdlevel', component: LocationComponent },
  { path: 'location/:firstlevel/:secondlevel', component: LocationComponent },
  { path: 'location/:firstlevel', component: LocationComponent },
  { path: 'location', component: LocationComponent },

  { path: 'story', component: StoryComponent },
  

  { path: 'research/:firstlevel/:secondlevel/:thirdlevel', component: ResearchComponent },
  { path: 'research/:firstlevel/:secondlevel', component: ResearchComponent },
  { path: 'research/:firstlevel', component: ResearchComponent },
  { path: 'research', component: ResearchComponent },

  { path: 'news/:firstlevel/:secondlevel/:thirdlevel', component: NewsComponent },
  { path: 'news/:firstlevel/:secondlevel', component: NewsComponent },
  { path: 'news/:firstlevel', component: NewsComponent },
  { path: 'news', component: NewsComponent },

  { path: 'prueba/:firstlevel/:secondlevel/:thirdlevel', component: PruebaComponent  },
  { path: 'prueba/:firstlevel/:secondlevel',   component: PruebaComponent  },
  { path: 'prueba/:firstlevel',   component: PruebaComponent  },
  { path: 'prueba', component: PruebaComponent }
];
