<section class="content">
  <section class="section" *ngIf="page">
    <div class="container">
      <h3 class="page-title">
        {{page.title}}
      </h3>
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
        </div>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
        <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
        <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
      </div>

      <!--<div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
        <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="false"></list>
      </div>-->

      <br />
      <div  *ngIf="contents.length > 0   && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
        <div class="box" *ngFor="let content of contents" [id]="content.content_uid">
          <article class="media">
            <div class="media-left" *ngIf="content.images.length">
              <figure class="image" name="publication-figure">
                <!-- img *ngIf="content.images.length" class="" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title" style="border-radius: 10px; " -->
                <img *ngIf="content.images.length" class="" [src]="content.images[0].url" [alt]="content.title" style="border-radius: 10px; ">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <!--<strong [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</strong>
                <span class="ml-1">{{content.sub_title}}</span>
                <br>-->
                <div [innerHTML]="content.description | safeHtml"></div>
              </div>
            </div>
          </article>
        </div>
      </div>

    </div>
  </section>
</section>
