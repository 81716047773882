<!--<div style="height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="min-width:13em; max-width:32em; padding-top:7em; margin: auto; z-index: 2; position: relative; ">
    <img src="../../../assets/images/events-banner-sharp.png" />
  </div>
</div>-->


<!--<div style="height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="min-width:18em; max-width:77em; width:20%; margin: auto; z-index: 2; position: relative;">
    <img src="../../../assets/images/events-sharp-banner.png" />
  </div>
</div>-->

<!--<div style="max-height:20em; ">
  <img src="../../../assets/images/rts-background.png" style="height: 20em; width: 100%; z-index:1; position:absolute;" />
  <div style="width: 77em; margin: auto;  z-index: 2; position: relative; ">
    <img src="../../../assets/images/events-banner.png" />
  </div>
</div>-->

<section class="content">
  <section class="section" *ngIf="page">
    <div class="container" >
      <!--<h3 class="title">
        {{page.title}}
      </h3>-->
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | oembed | safeHtml">
        </div>
      </div>
     

      <div class="m-b-sm m-t-sm" *ngIf="contents.length > 0">
        <div class="box" *ngFor="let content of contents" [id]="content.content_uid" style="width:85%">
          <article class="media">
            <div class="media-content" style="display: block; width: -webkit-fill-available;">
              <figure class="image" style="height:410px; width:550px;">
                <!-- <img *ngIf="content.images.length" style="height:400px; margin-left:10px" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"> -->
                <img *ngIf="content.images.length" style="height:400px; margin-left:10px" [src]="content.images[0].url" [alt]="content.title">
              </figure>
            </div>
            <div class="media-content" style="width:45rem;margin-left:5px;">
              <div class="content">
                <div style="font-size:28px" [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</div>
                <span class="ml-1">{{content.sub_title}}</span>
                <br>
                <div [innerHTML]="content.description | safeHtml"></div>
              </div>

              
              <!--<div>
                <a href="{{content.url}}" target="_blank"  class="w3-button w3-black" style="border-radius:3px;">RSVP</a>
              </div>-->

            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</section>
